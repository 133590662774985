import { FdrStyledTextBlock } from 'fdr/components/local/fdr-styled-text-block';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrHomeSearchForm } from 'fdr/components/local/fdr-home/fdr-home-search-form';

export const ErrorTextBlock = styled(FdrStyledTextBlock)(
  sx2CssThemeFn({
    mt: [5, '70px'],
    mb: ['65px', '80px'],
    px: [4, 0],
  })
);

export const ErrorPageSearchForm = styled(FdrHomeSearchForm)(
  sx2CssThemeFn({
    position: [null, 'relative'],
    top: [null, '0px'],
  })
);

export const SearchControlWrapper = styled.div(
  sx2CssThemeFn({
    backgroundImage: [
      null,
      'linear-gradient(180deg,#F5F4F4 0%,#F5F4F4 120px,#FFFFFF 120px,#FFFFFF 100%);',
    ],
    pt: [null, '45px'],
    position: [null, 'relative'],
    minHeight: [null, '255px'],

    '.searchControlFormLayoutWrapper': {
      mx: [null, 'auto'],
      border: [null, '1px solid'],
      borderColor: [null, 'borderGreyFifth'],
      borderRadius: [null, 'roundedTop'],
      boxShadow: [null, 'cardShadow'],
    },
  })
);
