import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const TextWrapper = styled.div(
  sx2CssThemeFn({
    p: [4, 4, 0],
    display: 'flex',
    margin: '0 auto',
    maxWidth: '700px',
    flexDirection: 'column',
    justifyContent: 'center',
    height: ['calc(100vh - 61px)', 'calc(100vh - 74px)'],
    fontSize: [2, 4],
    h1: {
      mb: [3, 4],
      fontSize: [3, '30px'],
    },
  })
);
