import React from 'react';
import { trackSearch } from '@hotelplan/libs.tracking';
import Container from 'components/domain/container/Container';
import { mapSearchControlFormStateToTrackableData } from 'fdr/components/candidate/fdr-search/mappers/fdr-search-control.mappers';
import {
  FdrPageComponents,
  useFdrPageComponentsContext,
  fdrPageComponentsPlaceholder,
  fdrCommonPageComponentsVariables,
} from 'fdr/components/local/fdr-page-components';
import { useFdr404PageComponentsQuery } from 'fdr/schemas/query/404/page-components/fdr-404-page-components.generated';
import { Fdr404PageContentQuery } from 'fdr/schemas/query/404/page-content/fdr-404-page-content.generated';
import {
  ErrorPageSearchForm,
  ErrorTextBlock,
  SearchControlWrapper,
} from './fdr-error-not-found.styles';

interface IFdrErrorNotFoundProps {
  content: Fdr404PageContentQuery['fdr404Page'];
}

const FdrErrorNotFound: React.FC<IFdrErrorNotFoundProps> = ({ content }) => {
  const { title, text, textFgColor, textBgColor, textBgImage } = content || {};

  const context = useFdrPageComponentsContext();
  const { data: pageComponents, loading: pageComponentsLoading } =
    useFdr404PageComponentsQuery({
      variables: { ...fdrCommonPageComponentsVariables, ...context },
    });

  return (
    <>
      <SearchControlWrapper>
        <ErrorPageSearchForm
          postSubmit={state => {
            return trackSearch({
              searchControl: mapSearchControlFormStateToTrackableData(state),
            });
          }}
        />
      </SearchControlWrapper>
      <Container>
        <ErrorTextBlock
          title={title || ''}
          text={text || ''}
          fontColor={textFgColor}
          backgroundColor={textBgColor}
          backgroundImage={textBgImage}
        />
      </Container>
      {pageComponentsLoading && !pageComponents ? (
        fdrPageComponentsPlaceholder
      ) : (
        <FdrPageComponents
          components={
            pageComponents?.fdr404Page?.pageComponents?.components || []
          }
        />
      )}
    </>
  );
};

export default FdrErrorNotFound;
