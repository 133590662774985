import Head from 'next/head';
import React from 'react';
import { GlobalStyle } from 'theme/globalStyles';
import { TextWrapper } from './fdr-error-internal.styles';

interface IFdrErrorInternalProps {
  text: string;
  title: string;
}

const FdrErrorInternal: React.FC<IFdrErrorInternalProps> = ({
  text,
  title,
}) => {
  return (
    <>
      <GlobalStyle />
      <Head>
        <title>{title}</title>
      </Head>
      <TextWrapper>
        <h1>{title}</h1>
        <div>{text}</div>
      </TextWrapper>
    </>
  );
};

export default FdrErrorInternal;
