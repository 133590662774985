import * as Sentry from '@sentry/nextjs';
import type { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  FdrErrorNotFound,
  FdrErrorInternal,
} from 'fdr/components/local/fdr-error';
import { Fdr404PageContentQuery } from 'fdr/schemas/query/404/page-content/fdr-404-page-content.generated';

export { getServerSideProps } from 'components/page/error/error.gssp';

const InternalErrorPage: React.FC = () => {
  const [t] = useTranslation();
  return (
    <FdrErrorInternal
      text={t('common:serverError.text')}
      title={t('common:serverError.heading')}
    />
  );
};

interface IErrorPageProps {
  err?: any;
  statusCode?: number;
  getServerSideProps?: boolean;
  content: Fdr404PageContentQuery['fdr404Page'];
}

const ErrorPage: NextPage<IErrorPageProps> = ({
  err,
  statusCode,
  getServerSideProps,
  content,
}) => {
  if (statusCode && statusCode !== 404) {
    if (!getServerSideProps && err) {
      // getInitialProps is not called in case of
      // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
      // err via _app.js, so it can be captured
      Sentry.captureException(err);
      // Flushing is not required in this case as it only happens on the client
    }

    return <InternalErrorPage />;
  }

  return <FdrErrorNotFound content={content} />;
};

export default ErrorPage;
